@import "../vendor/bootstrap/less/bootstrap";
@import "../vendor/font-awesome/less/font-awesome";
@import "fonts";
@import "variables";


html, body {
  height: 100%;
}

.logo{
  //margin-top: 20px;
  height: 100%;
  position: relative;
  @media (max-width:992px) {
   margin-left: 0px;

 }
}

.logo-drama{
  margin-left: -21px;
  @media (max-width:992px) {
    margin-top: 0px;
    margin-left: 0px;
  }
  @media (max-width:478px) {
    img{
      width: 59px;
    }
  }
  @media (max-width:392px) {

  }
}
.site-header button.navbar-toggle.collapsed{
 @media (max-width:438px) {
  margin-bottom: 30px;

}
}
.logo-wits{
 margin-right: 10px;
 @media (max-width:992px) {
  margin-bottom: 30px;
  margin-left: 0px;
  margin-top: 0px;
}
@media (max-width:478px) {
 margin-right: 10px;
 margin-top: 0px;
 img{
  width: 161px;
}

}

}

.navbar-form.new{
  display: inline-block;
}

.container{
  max-width: 100%;
}

#container {
  min-height: 100%;
}

.article-image {
  margin-bottom: @line-height-computed;
}

.article-image.align-left {
  padding-left: 0;
  padding-right: 15px;
}

.article-image.align-right {
  padding-right: 0;
  padding-left: 15px;
}

.article-image.align-center {
  text-align: center;
  .caption {
    text-align: left;
  }
  .thumbnail {
    display: inline-block;
  }
}

.thumbnail, .article-image {
  margin-bottom:0px;
}


.article-image.align-left, .article-image.align-right {

  @media (min-width: @screen-xs) {
    max-width: 50%;
  }

  @media (max-width: @screen-xs) {
    padding-left: 0;
    padding-right: 0;
  }

}

.mt-1 {
  margin-top: @line-height-computed * 1px;
}

.mt-2 {
  margin-top: @line-height-computed * 2px;
}

.mt-3 {
  margin-top: @line-height-computed * 3px;
}

.mb-1 {
  margin-bottom: @line-height-computed * 1;
}

.mb-2 {
  margin-bottom: @line-height-computed * 2;
}

.mb-3 {
  margin-bottom: @line-height-computed * 3;
}

.align-right {
  float: right;
}

.align-left {
  float: left;
}

.align-right, .align-left {
  @media (max-width: @screen-xs) {
    padding-left: 0;
    padding-right: 0;
    float: none;
  }
}

.clear-both {
  clear: both;
}

.clear-left {
  clear: left;
}

.clear-right {
  clear: right;
}

.text-align-center {
  text-align: center;
}

.vertical-align-middle {
  position: relative;
  top: 50%;
  .translate(0%, -50%);
}

.horizontal-align-center {
  .translate(-50%, -50%);
  left: 50%;
  position: absolute;
  z-index: 99;
}

.caption {
  font-size: @font-size-small;
  min-height: @font-size-small;
  box-sizing: content-box;

  p {
    margin-bottom: 0;
  }

  .article-image-attribution {
    float: right;
  }
}

.pull-quote {
  font-size: @font-size-large;
  border-top: 3px solid @text-color;
  border-bottom: 3px solid @text-color;
  padding: @panel-body-padding;
  margin-bottom: @font-size-base;
  text-align: center;
}

.page-title {
  text-align: left;
  margin-top: @font-size-h1;
  margin-bottom: @font-size-h1;

  &.page-title-left {
    text-align: left;
  }
}

.divider {
  width:100%;
  background: #00f0ff; /* Old browsers */
  background: -moz-linear-gradient(left, #00f0ff 0%, #ebacfd 99%, #ebacfd 100%, #ebacfd 100%, #ebacfd 100%, #ebacfd 100%, #2989d8 100%, #ebacfd 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #00f0ff 0%,#ebacfd 99%,#ebacfd 100%,#ebacfd 100%,#ebacfd 100%,#ebacfd 100%,#2989d8 100%,#ebacfd 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #00f0ff 0%,#ebacfd 99%,#ebacfd 100%,#ebacfd 100%,#ebacfd 100%,#ebacfd 100%,#2989d8 100%,#ebacfd 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00f0ff', endColorstr='#ebacfd',GradientType=1 ); /* IE6-9 */
  height:2px;
  margin-bottom:25px;
}

div.icon {
  background:url('/uploads/files/icon-bg.png');
  width:146px;
  height:35px;
  text-align:center;
  color:@white;
  padding-top:5px;
}

.article {
  padding-bottom: @padding-large-vertical;

  .article-title {
    &:extend(.page-title);
  }

  .article-byline {
    position: relative;
    text-align: left;
    margin-bottom: @font-size-h1;
  }

  h2 {
    margin-top: 1em;
    margin-bottom: 0.5em;
  }
}

#sidebar {
  padding-top: 1 * @font-size-h1;
}

.article-body {
  .clearfix();
}

h1, h3 {
  font-weight:300;
  text-transform:capitalize;
  position:relative;
  padding-bottom:10px;
}

.section-title > h1::after, .section-title > h2::after, .section-title > h3::after, .section-title > h4::after, .section-title > h5::after, .section-title > h6::after, h2.section-title::after {
  content: '';
  border-bottom: 1px solid #12b7dc;
  width: 150px;
  margin-left: auto;
  position: absolute;
  bottom: 0;
  left: 30px;
}

h2.section-title {
  position:relative;
  padding-bottom:10px;
}

h2.section-title::after {
  left:20px;
}

.section-content {
  margin-top:25px;
}


h4 {
  font-weight:500;
  position:relative;
  padding-bottom:10px;
}

.media {
  border: 1px solid @gray-light;
  padding: @panel-body-padding;
  margin-bottom: @font-size-base;
  border-radius: @border-radius-small;

  .media-right,
  .media > .pull-right {
    padding-left: @panel-body-padding;
    padding-bottom: @panel-body-padding;
  }

  .media-left,
  .media > .pull-left {
    padding-right: @panel-body-padding;
    padding-bottom: @panel-body-padding;
  }

  .media-date-byline {
    border-top: 1px solid @gray-light;
    border-bottom: 1px solid @gray-light;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-bottom: @padding-base-vertical;
  }

  .media-summary {
    p:last-child {
      margin-bottom: @padding-base-vertical;
    }
  }

  &.media-responsive {
    .media-right, .media-left {

      @media (max-width: @screen-xs) {
        display: block;
        float: none !important;
        padding-right: 0;
        padding-left: 0;

        .media-object {
          width: 100%;
        }
      }

      @media (min-width: @screen-xs + 1) {
        .media-object {
          width: 200px;
        }
      }

      @media (min-width: @screen-md) {
        .media-object {
          width: 160px;
        }
      }

    }
  }
}

.nav-stacked {
  .nav-stacked {
    > li > a {
      padding-left: 40px;
    }
  }
}

.edit-this-page {
  font-size: 14px !important;
  background: #fff;
  display: inline-block;
  padding: 4px;
  height: 22px;
  width: 22px;
  line-height: 14px;
  color: @gray;

  &:hover {
    background: @gray;
    color: #fff;
  }
}
#home-modal {
  display: block;

  .container {
    width: 100% !important;
  }
}


#footer {
  background: @gray;
}

.site-footer {
  background: @gray;
  color: #fff;
  text-align: left;
  padding: 50px 10px 10px 10px;
  font-weight:300;
  a {
    color:@white;
    &:hover, &:focus {
      color:@brand-primary;
      text-decoration: none;
    }
  }
  &.copyright {
    padding: 25px 10px;
    border-top:1px solid @brand-primary;
  }
}

.footermenu {
  margin-bottom: 25px;
  display:none;
}

.connect, .contact {
  display:none;
}

.maplink {
  margin-left:auto;
  margin-right:auto;
  background:@brand-primary;
  padding:25px 25px 25px 25px;
  text-align:center;
  margin-bottom:25px;
  display:none;
  a:hover, a:focus {
    color:@white;
  }
}

.copy-right {
  font-size:13px;
}

.copy-left {
  font-size:24px;
  color:@brand-primary;
  font-weight:500;
}

@media (min-width: @screen-md) {
  .copy-right {
    text-align: right;
  }
  .footermenu {
    padding-left: 60px;
    display:block;
  }
  .maplink {
    margin-top:-50px;
    width:90%;
  }
  .connect, .contact, .maplink {
    display:block;
  }
  article.article {
    padding-left: 13%;
    padding-right: 13%;
  }
}

// Nav bar dropdown

.navbar-nav > li.dropdown {
  > a {
    padding-right: 30px;
    &:after {
      .fa-icon();
      content: @fa-var-chevron-down;
      position: absolute;
      top: ~"calc(50% - 6px)";
      right: 9px;
      font-size: 0.75em;
    }
  }
}

// Nav form

.navbar-form {
  .form-control {
    background: transparent;
    border: 0;
    border-bottom: 1px solid @gray-light;
    border-radius: 0;
    color: @gray-lighter;
    box-shadow: none;
  }
}

// share

.article-sharing {
  min-height: 28px;
  text-align: center;
  margin-bottom: @font-size-h1;

}

.share-btn {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
  color: @text-color;
  font-size: 14px;
}

/* notifications */
#notifications {
  z-index: 101;
}

body.ltr #notifications {
  left: 0;
}

body.rtl #notifications {
  right: 0;
}

#notifications.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
}

#notifications {
  text-align: center;

  .notification {
    .alert;
  }
  .notification[class*="notice"] {
    .alert-info;
  }
  .notification[class*="error"] {
    .alert-danger;
  }

  .notification {
    margin-bottom: 0;
    border-radius: 0;

    a {
      color: inherit;
      &:hover {
        text-decoration: none;
      }
    }
  }

  .notification + .notification {
    border-top: 0;
  }

}

// Dropdown sub-menu

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px;
  border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropdown-submenu > a:after {
  .fa-icon();
  content: @fa-var-chevron-right;
  position: relative;
  top: ~"calc(50% - 6px)";
  right: -9px;
  font-size: 0.75em;
}

.dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}

h1.gtmwarning {
  text-align: center;
  background: red;
}

/* Gallery */

.grid-sizer, .grid-item {
  width: 33%;
  margin-bottom: 5px;

}

.grid-item-width2 {
  width: percentage(8/12);
}

.grid-item-height2 {
  img {
    width: 100%;
    overflow: hidden;
  }
  .lightbox {
    padding-bottom: 100%;
  }
}

.gallery-link {
  position: relative;

  a:hover .caption {
    display: block;
  }

  .caption {
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: #ffffff;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
  }

}

.mfp-description {
  color: #ccc;
  margin-top: 10px;
}

a.thumbnail:hover, a.thumbnail:active {
  text-decoration: none;
}



.button {
  padding: 10px 15px;
  background: @brand-primary;
  color: @white;
  border:2px solid @brand-primary;
  border-radius:3px;
  margin-right: 25px;
  font-size:@font-size-base;
  font-weight:500;
  text-transform: uppercase;
  white-space:nowrap;
  &.search{
    padding: 7px 12px;
    margin-bottom: 0;
    border: 0;
    margin: 0;
    &:hover{
      border: 0px solid #0e90ad; 
    }
  }
  &.orange {
    background: @brand-secondary;
    border:2px solid @brand-secondary;
  }
  &.clear {
    background: none;
    border: 2px solid @white;
  }
  &.white {
    color:@gray;
    border: 2px solid @gray;
    background:none;
    padding: 5px 8px;
    text-transform: none;
    font-weight:300;
    display:block;
    text-align:center;
    margin-bottom:20px;
  }
  &.readmore {
    font-weight:300;
    background:@gray;
    padding:5px 8px;
    text-transform: capitalize;
    border:2px solid @gray;
  }
  &:hover, &:focus {
    color:@white;
    text-decoration:none;
    background-color:@brand-primary-darken;
    border:2px solid @brand-primary-darken;
  }
  &.square-left {
    border-bottom-left-radius:0px;
    border-top-left-radius:0px;
  }
}

a.list-group-item:focus, a.list-group-item:hover, button.list-group-item:focus, button.list-group-item:hover {
  color:@white;
  text-decoration:none;
  background-color:@brand-primary-darken;
  border:2px solid @brand-primary-darken;
}

.newsletteremail {
  border: none;
  padding: 12px;
  font-size: 16px;
  margin-right: -5px;
  width: 78%;
  border-top-left-radius:3px;
  border-bottom-left-radius:3px;
  color:@gray;
}

.additionallinks {
  float:left;
  width:100%;
}

.space {
  display:none;
}

@media (min-width: @screen-md-1) {
  .space {
    display:block;
  }
}

@media (min-width: @screen-sm) {
  .additionallinks {
    width:50%;
  }
}

@media (min-width: @screen-lg) {
  .additionallinks {
    width:auto;
  }
}

.vertcentre {
  position: relative;
  top: 50%;
  transform: translateY(0%);
}

.social {
  padding-left: 30px;
}

.coursetitle {
  background:@brand-primary;
  margin:20px 20px 20px 0px;
  padding:20px 20px 60px 20px;
  color:@white;
  position: relative;
  > .button.readmore {
    position: absolute;
    bottom:20px;
  }
}

.blogdiv {
  background: @brand-primary;
  height:3px;
  width:65px;
  margin-top:10px;
  &.orange {
    background: @brand-secondary;
  }
}



//header
.navbar-brand {
  height:auto;
  padding:13px 15px 0px 15px;
}

.site-header {
  background: @white;
  margin-bottom:0px;
  font-size:@font-size-base;
  font-weight: 300;
  .right {
    text-align:right;
  }
  .row {
    padding-top:10px;
    padding-bottom:0px;
  }
  .blue {
    border-bottom:1px solid @brand-primary;
    padding-bottom:20px;
  }
  .row.blue.right {
    display:none;
  }
  button.navbar-toggle.collapsed {
    position: relative;
    top: 50%;
    transform: translateY(30%);
  }
}

.navbar-default .navbar-nav>li>a, .navbar-default .navbar-nav>li>a:hover, .navbar-default .navbar-nav>li>a:focus, .navbar-nav>li.dropdown>a:hover, .navbar-nav>li.dropdown>a:focus, .navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:focus, .navbar-default .navbar-nav>.open>a:hover, .navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover {
  color:@gray;
  text-transform:none;
  border-bottom:1px solid transparent;
}

.navbar-default .navbar-nav>li>a:hover, .navbar-default .navbar-nav>li>a:focus, .navbar-nav>li.dropdown>a:hover, .navbar-nav>li.dropdown>a:focus, .navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:focus, .navbar-default .navbar-nav>.open>a:hover, .navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover {
  border-bottom: 1px solid @brand-secondary;
}

.nav>li>a {
  margin: 10px 0px 10px 15px;
  padding:0px;
}

.navbar-nav {
  margin:0px;
}

div#primaryNav {
  padding: 0px;
}

@media (min-width: @screen-md) {
  .site-header {
    .navigation {
      float:right;
    }
    .row.blue.right {
      display:block;
    }
    div#primaryNav {
      margin-left: -25px;
    }
  }
}


.container-fluid>.navbar-collapse, .container-fluid>.navbar-header, .container>.navbar-collapse, .container>.navbar-header {
 margin-right: 0px; 
 margin-left: 0px; 
}
//carousel

.carousel-caption, .carousel-control {
  text-shadow:none;
}

h1.caption {
  font-size: @font-size-h2;
  font-weight:400;
  text-transform:uppercase;
  display:none;
}

h1.caption::after {
  border:none;
}

h2.caption {
  font-size: @font-size-h3;
  font-weight:300;
  margin-bottom: 35px;
  margin-top: 0px;
  text-transform: none;
  display:none;
}

@media (min-width: @screen-s) {
  h1.caption, h2.caption {
    display:block;
  }
}

@media (min-width: 865px) {
  h1.caption {
    font-size: @font-size-h1;
  }
  h2.caption {
    font-size: @font-size-h2;
  }
}

@media (min-width: @screen-lg) {
  h1.caption {
    font-size: @font-size-h0;
  }
  h2.caption {
    font-size: @font-size-h1;
  }
}

a.bloglink{
  text-decoration:none;
  h4 {
    color:@gray;
  }
  &:hover, &:focus {
    h4 {
      color:@brand-primary;
    }
  }
}

.blogimage {
  position: relative;
}

.bloglabel {
  position:absolute;
  bottom:0;
  right:5%;
  color:@white;
  background:@gray;
  padding:1px 10px;
  &.blue {
    background: @brand-primary;
  }
  &.orange {
    background: @brand-secondary;
  }
}

.pageintro {
  margin-bottom:25px;
}

.gmap {
  padding-bottom: percentage(16/16);
}

@media (min-width: @screen-md) {
  p.contactpage, .gmap {
    margin-top: 30px;
  }
}

@media (min-width: @screen-ss) and (max-width: @screen-md){
  .col-md-4.blog {
    width:50%;
    float:left;
  }
}

@media (min-width: @screen-s) {
 br.mini {
  display:none;
}
}

@media (min-width: @screen-lg){
  .container {
    width: 1080px;
  }
}

.flowfeeds-container .flowfeeds-item {
  border-radius: 0px;
  width: 100%;
  background: #fff;
  box-shadow: none;
  margin-bottom: 20px;
  padding: 0px;
  border: 1px solid @gray;
  margin-right:25px;
  position:relative !important;
}

.flowfeeds-footer {
  width: 100%;
  padding: 10px 15px;
  background: @brand-primary;
  color: #ffffff;
  font-size:16px;
  font-weight:300;
  line-height: 30px;
}

.flowfeeds-container .flowfeeds-footer .flowfeeds-social-icon {
  float: left;
  padding-right: 15px;
}

.flowfeeds-container .flowfeeds-caption {
  margin-bottom: 0px;
  padding: 15px; 
}

.icon-facebook:before, .icon-twitter:before, .icon-instagram:before {
  font-size:30px;
  width:30px;
  line-height: 30px;
  font: normal normal normal 30px/1 FontAwesome;
}

.icon-facebook:before {
  content: '\f09a';
}

.gallerythumb {
  height: 300px;
  background-size: cover;
  background-position: center;
}

.text-white{
  color:@white;
}
.bg-light{
  background-color:#eee;
}
.bg-dark{
  background-color:@gray-base;
}
.p-0{
  padding:0px !important;
}
.p-1{
  padding: 10px;
}
.p-2{
  padding: 20px;
}  
.pt-0{
  padding-top: 0;
}
.pb-0{
  padding-bottom: 0;
}
.pt-1{
  padding-top:10px;
}
.pb-1{
  padding-bottom:10px;
}
.pt-2{
  padding-top:25px;
}
.pb-2{
  padding-bottom:25px;
}
.pb-5{
  padding-bottom:50px;
}
.pt-5{
  padding-top:50px;
}
.px-xs-0{
  @media(max-width: @screen-xs) {
    padding-left:0px !important;
    padding-right:0px !important;
  }
}
.px-0{
  padding-left:0px !important;
  padding-right:0px !important;
}
.w-100{
  width:100%;
}
.w-75{
  width:75%;
}
.bg-cover{
  background-size: cover;
} 
.bg-fixed{
  background-attachment: fixed !important;
}
.text-light{
  color:@white;
}
.lead{
  font-size: 22px;
}
.nmt-25{
  margin-top: -25px;
}
.m-auto{
  margin: auto;
}
.ml-auto{
  margin-left: auto;
}
.mr-auto{
  margin-right: auto;
}
.pr-1{
  padding-right:10px;
}
.mr-1{
  margin-right:10px;
}
.ml-1{
  margin-left:10px;
}
.ml-md-1{
  @media(min-width: @screen-md){
    margin-left: 10px;
}
}
.m-0{
  margin: 0;
}
.m-md-0{
  @media(min-width: @screen-md){
    margin: 0;
  }
}
.mt-0{
  margin-top: 0;
}
.mb-0{
  margin-bottom: 0;
}

.font-weight-bold{
  font-weight: bold;
}
.h1{
  font-size: 37px;;
}

.bg-white{
  background-color: @white;
}

.media-full{
  border: none;
  border-radius: 0;
}
.media-full > a{
  text-decoration: none;
  display: block;
  .text-hover{
    color: @text-color;
  }
  &:hover {
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 50%);
    .text-hover{
      color: @brand-primary !important;
      text-decoration: none;
    }  
  }
}
.text-dark{
  color: @text-color;
}

.rounded-0{
  border-radius: 0 !important;
}

.border-1{
  border: 1px solid;
}

.border-2{
  border: 1px solid;
}

.border-3{
  border: 3px solid;
}
.border-light{
  border-color: #eee;
}
.bg-lighter{
  background-color: lighten(@gray, 70%);
}
.border-bottom-primary{
  border-bottom: 1px solid @brand-primary;
}

.border-left-0{
  border-left: 0;
}

.border-0{
  border:none;
}
.sidebar-menu {
  padding-left: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 1rem;
  z-index: 1020;

    .active a, a:hover{
      background-color: #00509E !important;  
      color: @white !important;
      box-shadow: 0px 0px 9px rgba(0, 0, 0, 50%);
      border: 1px solid #00509E;
    }
}
.d-flex{
  display: flex;
}
.d-md-flex{
  @media(min-width: @screen-md) {
      display: flex !important;
  }
}
.d-sm-flex{
  @media(min-width: @screen-sm) {
      display: flex !important;
  }
}
.flex-column{
  flex-direction: column;
}
.flex-row{
  flex-direction: row;
}
.flex-sm-row{
  @media(min-width: @screen-sm){
      flex-direction: row;
  }
}
.flex-wrap{
  flex-wrap: wrap;
}
.align-items-center{
  align-items: center;
}
.justify-content-center{
  justify-content: center;
}
.justify-content-end{
  justify-content: end;
}
.justify-content-between{
  justify-content: space-around;
}

.text-blue-dark{
  color: #00509E;
}
.bg-dark-blue{
  background-color: #00509E;
}

.mt-auto{
  margin-top: auto;
}

.bottom-1{
  bottom:1rem;
} 
.right-1{
  right:1rem;
}

.position-absolute{
  position: absolute;
}
.position-relative{
  position: relative;
}

.w-md-auto{
  @media(min-width: @screen-md) {
    width: auto !important
  }
}

.ml-md-auto{
  @media(min-width: @screen-md) {
    margin-left: auto !important
  }
}

.font-sm-lg{
  @media(max-width: @screen-sm) {
    font-size: @font-size-h3 !important
  }
}

.font-md-base{
  @media(min-width: @screen-md) {
    font-size: @font-size-base !important
  }
}

.btn-dark-blue{
  background-color: #00509E;
  color:@white;
  &:hover{
    background-color: darken(#00509E,10%);
    color:@white;
  }
}

.font-xl{
  @media(min-width: @screen-md) {
    font-size:50px
  }
}

[data-slick-carousel-logo]{
  .slick-dots {
    position: relative;
    bottom: -15px;
    li button:before{
      color: @brand-primary;
      font-size: 12px;
    }
  }  
}